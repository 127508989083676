export const tableOption = {
  searchMenuSpan: 6,
  columnBtn: false,
  border: true,
  selection: true,
  index: false,
  indexLabel: '序号',
  stripe: true,
  menuAlign: 'center',
  menuWidth: 350,
  align: 'center',
  refreshBtn: true,
  searchSize: 'mini',
  addBtn: false,
  editBtn: false,
  delBtn: false,
  viewBtn: false,
  props: {
    label: 'label',
    value: 'value'
  },
  column: [{
    label: '成员照片',
    prop: 'url',
    type: 'upload',
    slot: true,
    listType: 'picture-img'
  }, {
    label: '团队',
    prop: 'teamName'
  }, {
    label: '团队顺序',
    prop: 'teamIndex'
  }, {
    label: '成员',
    prop: 'userName'
  }, {
    label: '顺序',
    prop: 'userIndex'
  }, {
    label: '内容',
    prop: 'showContent'
  }, {
    width: 150,
    label: '状态',
    prop: 'status',
    search: true,
    type: 'select',
    dicData: [
      {
        label: '待发布',
        value: 0
      }, {
        label: '发布',
        value: 1
      }
    ]
  }, {
    label: '备注',
    prop: 'remark'
  }]
}
